// Leaving privacy policy name, as template in cms can not be renamed
// without completely removing it, actually that's template for info pages
import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Seo from "components/Seo"
import Layout from "components/Layout"
import Content from "components/PrivacyPolicy/Content"

const PrivacyPolicy = ({ data, location }) => {
  if (!data) return null

  const page = data.prismicPrivacyPolicy

  return (
    <Layout location={location}>
      <Seo
        title={page.data.seo_title.text}
        description={page.data.seo_description.text}
        keywords={page.data.keywords.text}
        location={location}
      />

      <Content
        contentHtml={page.data.page_content.html}
        heading={page.data.title.text}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrivacyPolicyQuery($id: String) {
    prismicPrivacyPolicy(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_content {
          html
        }
        title {
          text
        }
        seo_description {
          text
        }
        keywords {
          text
        }
        seo_title {
          text
        }
      }
      url
    }
  }
`

export default withPrismicPreview(PrivacyPolicy)
