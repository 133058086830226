import React from "react"
import styled from "styled-components"
import { P1CSS, H2CSS, H1CSS, Container, Wrapper, InnerContainer } from "@components/styled"
import { media } from "@styles/theme"

const PrivacyWrapper = styled(Wrapper)`
  padding: 30vh 0;

  @media ${media.s} {
    padding: 18vh 0 22vh 0;
  }

  h1 {
    ${H1CSS};
    color: ${({theme}) => theme.colors.dark};
    margin: 0 0 80px 0;
    padding-bottom: 45px;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey};

    @media ${media.s} {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }
`

const Content = styled.div`
  width: 64%;
  margin: auto;

  @media ${media.sm} {
    width: 76%;
  }

  @media ${media.s} {
    width: 100%;
  }
`;

const CMSContent = styled.div`
  display: flex;
  flex-direction: column;

  * {
    color: ${({ theme }) => theme.colors.dark};
  }

  p {
    ${P1CSS};
    overflow-wrap: break-word;
    font-size: 20px;
  }

  h2 {
    ${H2CSS};
    color: ${({ theme }) => theme.colors.dark};
    margin-top: 80px;
    font-size: 30px;

    @media ${media.s} {
      margin-top: 50px;
    }
  }

  ol {
    margin-bottom: 0;
    padding-left: 20px;
  }

  ul {
    margin-left: 20px;

    li {
      padding-left: 5px;
    }
  }

  li {
    ${P1CSS};
    font-size: 20px;
    padding-left: 20px;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

const PrivacyContent: React.FC<{ contentHtml: any; heading: string }> = ({
  contentHtml,
  heading,
}) => {
  //TODO: fix numeration of nested lists.
  return (
    <PrivacyWrapper>
      <Container>
        <InnerContainer>
          <Content>
            <h1>{heading}</h1>
            <CMSContent dangerouslySetInnerHTML={{ __html: contentHtml }} />
          </Content>
        </InnerContainer>
      </Container>
    </PrivacyWrapper>
  )
}

export default PrivacyContent
